<template>
    <div class="contenter" style="height:100%;padding-top:88px;padding-bottom:54px;">
        <Header style="margin-top:-88px;position: relative;" />
        <div class="public-resource">
            <div class="side-nav">
                <dl>
                    <dt>公共数据资源</dt>
                    <dd v-for="item in list" :key="item.id">
                        <router-link :to="{path:'/publicResource',query:{id:item.id}}" :class="{'active':id===item.id}" href="javascript:;">
                            <span class="color-block"></span>{{item.dbName}}
                        </router-link>
                    </dd>
                </dl>
            </div>

            <div class="content">
                <div class="con-title">
                    <div class="line">
                        {{dataObj.dbName}}
                    </div>
                </div>
                <div class="org">
                    <label>执行机构：</label>{{dataObj.orgName}}
                </div>
                <div class="intro">
                    <label>数据介绍：</label>
                    <div class="intro-con" v-html="dataObj.intro"></div>
                </div>
                <div class="link">
                    <a :href="dataObj.link" target="_blank" rel="noopener noreferrer">点击前往</a>
                </div>
                <div class="tips">说明：公共数据资源是EPS DATA 为用户搜集、整理的重要外部调查数据资源介绍和入口，这些调查数据并非EPS DATA所有，我们仅提供数据资源列表和入口服务。</div>
            </div>
            <div class="clear"></div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from './commons/Header.vue';
import Footer from './commons/Footer.vue';
export default {
    name:'PublicResource',
    components:{Header,Footer}, 
    data() {
        return {
            list:require('../../public/json/PublicDataResource.json'),
            dataObj:{}
        }
    },
    computed:{
        id() {
            let id = this.$route.query.id;
            let result;
            if(id) {
                result = parseInt(id);
            } else {
                result = this.list[0]?this.list[0].id:0;
            }
            return result;
        }
    },
    watch:{
        id(value) {
            this.list.forEach(item=>{
                if(item.id===value) {
                    this.dataObj = item;
                }
            })
        }
    },
    mounted() {
        this.list.forEach(item=>{
            if(item.id===this.id) {
                this.dataObj = item;
            }
        });
        window.scrollTo(0,0);
    }
}
</script>
<style lang="scss">
.intro-con p {
    color:#666;
    font-size: 16px;
}
</style>
<style lang="scss" scoped>
@import '../scss/publicResource.scss';
</style>